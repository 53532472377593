import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { loginAPI } from '../../api/api';
import './login.css';
import { Redirect, useNavigate, useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setAuth } from '../../redux/actions/AuthActions';
import eye from '../../assets/media/icons/eye.svg';
import { useCookies } from 'react-cookie';
import Forgot from './forgot';
// import SessionExpire from './sessionExpire';

// Validation Errors

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Password Is Required';
  }
  return errors;
};

// Main Component

export default function _login(props) {
  const [capslockWarning, setCapslockWarning] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  // const isAuthenticated = localStorage.getItem("isAuthenticated");
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const site = props.site;
  // const params = useParams();
  const [cookies, setCookies, removeCookies] = useCookies(['accessToken']);
  const [able, setAble] = useState(false);
  const [view, setView] = useState(false);

  const onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState('CapsLock')) {
      setCapslockWarning(true);
    } else {
      setCapslockWarning(false);
    }
  };

  // Function to submit values
  // if(history && props.verified === true){
  //   var verifySucess = history.location.search;
  //   if(verifySucess != '' && verifySucess.split('=')[1] === 'success'){
  //     toast.success("Verified Successful.", {
  //       autoClose: 2500,
  //       hideProgressBar: true,
  //     });
  //     props.setVerified(false);
  //   }
  // }

  const handleSubmit = (values) => {
    setAble(true);
    axios
      .post(loginAPI, values)
      .then(function (response) {
        localStorage.setItem('userActive', JSON.stringify(response.data.user));

        // dispatch(setAuth(response.data));

        setCookies('userInformation', JSON.stringify(response.data.user), {
          path: '/',
          maxAge: response.data.expires_in,
        });

        setCookies('accessToken', response.data.access_token, {
          path: '/',
          maxAge: response.data.expires_in,
        });

        localStorage.setItem('sessionTime', response.data.expires_in);

        toast.success('Login Successful', {
          autoClose: 2000,
          hideProgressBar: true,
        });
        navigate('/market', { replace: true });
      })
      .catch(function (error) {
        console.log(error.response, 'error');
        setAble(false);
        if (error.response.status === 401) {
          if (error.response.data.message) {
            toast.error(error.response.data.message, {
              hideProgressBar: true,
              autoClose: 2000,
            });
          } else {
            toast.error(error.response.data.error, {
              hideProgressBar: true,
              autoClose: 2000,
            });
          }
        } else {
          toast.error('Your email or password is incorrect');
          // console.log(error.response.data);
        }
      });
  };

  const showMyCookies = () => {
    // console.log("Showing Available Cookies", cookies.user);
  };

  const formik = useFormik({
    initialValues: {
      site: site,
      email: '',
      password: '',
    },
    validate,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleForgot = () => {
    setForgotModal(true);
  };
  const closeModal = () => {
    setForgotModal(false);
  };

  if (cookies.accessToken) {
    // console.log('yes here');
    navigate('/', { replace: true });
    // return <Redirect to="/market" />;
  } else {
    return (
      <>
        <form className="form" onSubmit={formik.handleSubmit}>
          <input
            name="site"
            type="hidden"
            onChange={formik.handleChange}
            value={formik.values.site}
          />
          <div className="form-group">
            <label htmlFor="email">Email</label>

            <input
              className={`form-control`}
              placeholder="Enter your email"
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />

            {formik.errors.email ? (
              <span className="text-danger">{formik.errors.email}</span>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              className={`form-control`}
              placeholder="Enter your password"
              id="password"
              name="password"
              type={view ? 'text' : 'password'}
              onKeyDown={onKeyDown}
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {formik.values.password.length > 0 ? (
              <span className="eye-icon" onClick={() => setView(!view)}>
                <img src={eye} alt="view" />
              </span>
            ) : null}
            {capslockWarning ? (
              <span style={styles.capsWarning}>CAPSLOCK is ON!</span>
            ) : (
              ''
            )}
            {formik.errors.password ? (
              <span className="text-danger"> {formik.errors.password} </span>
            ) : null}
          </div>

          <button className="btn mt-0" type="submit" disabled={able}>
            Sign In
          </button>
        </form>
        <div className="form-group d-flex align-items-center">
          <span className="forgot-btn mr-1" onClick={handleForgot}>
            Forgot Your Password?
          </span>
          {/* <span className="forgot-btn ml-1"><Link to="/signup">Sign Up</Link></span> */}
        </div>
        <hr className="seperator  my-2" />
        <div className="my-3 d-inline-flex flex-wrap align-items-center justify-content-center w-100">
          <p className="mr-2 mb-0 w-100 text-center" style={styles.memberText}>
            Not registered yet?
          </p>
          <button
            className="btn btn-primary secondary mt-2 w-100"
            style={{
              border: '1px solid #3969d5',
              background: 'transparent',
              minWidth: '100px',
              minHeight: '40px',
            }}
          >
            <Link
              to="/signup"
              style={{
                color: '#3969d5',
                textDecoration: 'none',
                display: 'inline-block',
                width: '100%',
              }}
            >
              Create Your Account
            </Link>
          </button>
        </div>
        <Forgot forgotModal={forgotModal} closeModal={closeModal} site={site} />
        {/* <SessionExpire sessionModal={sessionModal} closeModal={closeModal}/> */}
      </>
    );
  }
}

const styles = {
  capsWarning: {
    position: 'absolute',
    fontSize: '12px',
    lineHeight: '1.1',
    right: '0',
    top: '5px',
    color: '#ffb100',
  },
  memberText: {
    color: '#3d3d3d',
  },
};
