import React, { useState, useEffect } from 'react';
import welcomeScreen from '../../assets/media/images/welcomeScreen.svg';
import { Link, useNavigate } from 'react-router-dom';

const Welcome = () => {
  const [newUser, setNewUser] = useState('');
  const history = useNavigate();

  useEffect(() => {
    if (history) {
      console.log(history, 'welcome history');
      if (history.location.search != '') {
        setNewUser(history.location.search.split('=')[1].split('%')[0]);
      }
    }
  }, []);

  return (
    <section className="welcome-sec" style={styles.WelcomeSection}>
      <div className="container-fluid d-flex flex-wrap justify-content-center align-items-center">
        <div className="col-12 d-flex flex-wrap justify-content-center align-items-center">
          <div style={styles.WelcomeScreenContent} className="col-6">
            <h4 style={styles.ScreenContentHeading}>
              Welcome to{' '}
              {window.location.host === 'analyst.alphacapital.com.pk'
                ? 'Alpha Analyst'
                : 'Ask Analyst'}
              , <a style={styles.User}>{newUser}</a>
            </h4>
            <p style={styles.ScreenContentParagraph}>
              You have successfully verified your email address.
            </p>
            <Link
              to={'/'}
              replace
              className="btn btn-primary"
              style={styles.ScreenContentLink}
            >
              Click here to proceed
            </Link>
          </div>

          <div className="col-5">
            <img src={welcomeScreen} style={styles.WelcomeScreenImg} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;

const styles = {
  WelcomeSection: {
    height: '100vh',
    background: '#ffffff',
    display: 'inline-flex',
    alignItem: 'center',
    flexWrap: 'wrap',
    padding: '70px 30px 30px',
    width: '100%',
  },
  WelcomeScreenImg: {
    maxWidth: '425px',
  },
  ScreenContentHeading: {
    fontWeight: '700',
    lineHeight: '38px',
    fontSize: '32px',
    color: '#3d3d3d',
  },
  ScreenContentParagraph: {
    fontSize: '23px',
    margin: '15px 0px 15px 0px',
    color: '#3d3d3d',
  },
  ScreenContentLink: {
    background: '#3969d5',
    marginTop: '20px',
    fontSize: '18px',
  },
  User: {
    color: '#3969d5',
    textTransform: 'capitalize',
  },
};
