import React, { useEffect, useState } from 'react';
import Nav1 from '../nav1';
import Header from '../../../common/header/header';
import './morningbriefing.css';
// import Link from 'react-router-dom';
import { pdf, baseUrl, editmodal } from '../../../api/baseUrl';
import AkseerLogo from '../../../../src/assets/media/images/akseer-logo-new.png';
import axios from 'axios';
import { useDisclosure } from '@mantine/hooks';
import { formatDate } from '../../../GlobalFunctions';
import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Drawer, Select, rem } from '@mantine/core';
import { IconXboxX } from '@tabler/icons-react';
import { IconChevronDown } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
// import Setting from './Setting';

function MB() {
  const [MB, setMB] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // console.log(userInfo);
  useEffect(() => {
    axios
      .get(`${baseUrl}/mb`)
      .then(function (response) {
        setMB(response.data);
        setIsLoading(true);
      })
      .catch(function (error) {
        console.log('KSA MB====>', error);
      });
  }, []);

  return (
    <>
      <Header />
      <div className="investment-main analyst">
        <div className="row investmentboxed" style={styles.navWidth}>
          <Nav1 nopadding={true} />
        </div>
      </div>
      <div className="morning-content">
        <div className="container container_box">
          <div className="morning-ksa">
            <div className="ksa-pdf-icon">
              <a
                target="_blank"
                href={
                  isLoading
                    ? `https://pdf.akseerresearch.com/ksa/morning/${MB[0]['filename']}`
                    : '#'
                }
                rel="noreferrer"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: pdf,
                  }}
                />
              </a>
            </div>
            <div className="ksa-content">
              <h1>KSA</h1>
              <span>.</span>
              <p>{isLoading ? formatDate(MB[0]['date']) : ''}</p>
            </div>
            <div className="morning-text">
              <h1>Morning Briefing</h1>
              <img src={AkseerLogo} alt="" />
            </div>
            <div className="morning-topic">
              <table>
                <tr>
                  <th className="topic-left">Topic</th>
                  <th className="category-left">Category</th>
                </tr>
                {MB.map((value, index) => {
                  return (
                    <tr>
                      <td>
                        <a href={value.link} target="_blank">
                          {value.title}
                        </a>
                      </td>
                      <td>
                        <span className="economy-grey">{value.sector}</span> |{' '}
                        <span
                          className={
                            value.type == 'Positive'
                              ? 'positive-green'
                              : value.type == 'Negative'
                              ? 'negative-red'
                              : 'neutral-black'
                          }
                        >
                          {value.type}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const styles = {
  companylogo: {
    width: '280px',
    float: 'right',
  },
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  companyname: {
    width: '100%',
    fontSize: '14px',
    justifyContent: 'space-between',
    marginLeft: '0px',
    marginRight: '0px',
  },
};

export default MB;
