import React, { createContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from 'react-router-dom';
import Login from './layout/login/login';
import Signup from './components/Signup/signup';
import Market from './components/market';
import MB from './components/Publisher/morningbriefing';
import Reset from './components/ResetPassword/reset';
import Welcome from './layout/flowscreen/welcome';
import Expire from './layout/flowscreen/expire';
import ResetExpire from './layout/flowscreen/resetexpire';
import { createTheme, MantineProvider } from '@mantine/core';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Research from './components/market/researchreport';
import latestnews from './components/market/latestnews';
import Report from './components/market/reportlisting';
import '@mantine/core/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
const Currentprice = createContext();

function App(props) {
  const theme = createTheme({
    /* Your theme override here */
  });
  return (
    <>
      <MantineProvider theme={theme}>
        <Notifications />
        <ToastContainer
          draggable={false}
          transition={Bounce}
          autoClose={5000}
          position="bottom-right"
        />
        <Currentprice.Provider value={{ name: 'sulaiman', fname: 'jawaid' }}>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/welcome/:name?" element={<Welcome />} />
              <Route path="/expire" element={<Expire />} />
              <Route path="/reset_expire" element={<ResetExpire />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/reset_password/:token?" element={<Reset />} />
              <Route path="/dashboard" element={<Market />} />
              <Route path="/market" element={<Market />} />
              <Route path="/publisher/morningbriefing" element={<MB />} />
              <Route path="/market/reportlisting" element={<Report />} />
              <Route path="/market/latestnews" element={<latestnews />} />
              <Route path="/market/researchreport" element={<Research />} />
            </Routes>
          </Router>
        </Currentprice.Provider>
      </MantineProvider>
    </>
  );
}

export default App;
export { Currentprice };
