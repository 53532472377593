import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { resetAPI } from '../../api/api';
import './reset.css';
import { Link, Redirect, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../redux/actions/AuthActions';
import eye from '../../assets/media/icons/eye.svg';

// Validation Errors

const validate = (values) => {
  const errors = {};

  // if (!values.password) {
  //   errors.password = 'New Password Is Required';
  // }

  // if (!values.password_confirmation) {
  //   errors.password_confirmation = 'Please re-type your password here';
  // } else if ( values.password !== values.password_confirmation ) {
  //   errors.password_confirmation = 'Passwords does not match!';
  // }

  if (!values.password) {
    errors.password = 'Password is Required';
  } else if (values.password) {
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#^$%&_`~=/><,;:"'\^\+\-\.\*\(\)\|\[\]\{\}\\])[A-Za-z\d!@#$%&_`~=/?><,;:"'\^\+\-\.\*\(\)\|\[\]\{\}\\]{6,}$/i.test(
        values.password
      )
    ) {
      errors.password =
        'Password must be at least 6 characters including Alphanumeric and Special character';
    } else if (values.password.length <= 5) {
      console.log(values.password.length, 'values.password.length');
      errors.password = 'Password length atleast 6 characters';
    } else {
      if (!values.password_confirmation) {
        errors.password_confirmation = 'Please type your password again';
      } else if (values.password_confirmation) {
        if (values.password !== values.password_confirmation) {
          errors.password_confirmation = 'Password does not match';
        }
      }
    }
  }

  return errors;
};

// Main Component

export default function _reset() {
  // const isAuthenticated = localStorage.getItem("isAuthenticated");
  const dispatch = useDispatch();
  const history = useNavigate();
  let paramToken;
  const [view, setView] = useState(false);

  // Function to submit values
  // console.log(history , "history reset");

  if (history) {
    paramToken = history.location.search.split('=')[1];
  }

  const handleSubmit = (values) => {
    debugger;
    values = { ...values, token: paramToken };
    // console.log(values , "reset values.");
    axios
      .post(resetAPI, values)
      .then(function (response) {
        toast.success('Your password reset successful!');
        history.push('/');
      })
      .catch(function (error) {
        toast.error(error.response.data.error);
        // console.log(error);
        // console.log(error.response);
      });
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    // validationSchema: validate,
    validate,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <>
      <form className="form" onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="password">New Password</label>
          <input
            className={`form-control`}
            id="password"
            name="password"
            type={view ? 'text' : 'password'}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {formik.errors.password ? (
            <span className="text-danger"> {formik.errors.password} </span>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="password">Confirm Password</label>
          <input
            className={`form-control`}
            id="password_confirmation"
            name="password_confirmation"
            type={view ? 'text' : 'password'}
            onChange={formik.handleChange}
            value={formik.values.password_confirmation}
          />
          {formik.values.password_confirmation.length > 0 ? (
            <span className="eye-icon" onClick={() => setView(!view)}>
              <img src={eye} alt="view" />
            </span>
          ) : null}
          {formik.errors.password_confirmation ? (
            <span className="text-danger">
              {' '}
              {formik.errors.password_confirmation}{' '}
            </span>
          ) : null}
        </div>
        <button className="btn m-0" type="submit">
          Reset Password
        </button>
      </form>
    </>
  );
}

// {"data":{"access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXNrYW5hbHlzdC10ZXN0aW5nLmFrc2VlcnJlc2VhcmNoLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTY0ODcyNDc1NiwiZXhwIjoxNjQ4NzI4MzU2LCJuYmYiOjE2NDg3MjQ3NTYsImp0aSI6InFKbnNQbkdiWFZIekh4SFAiLCJzdWIiOjIsInBydiI6IjEyNzQ1ZjRiNTBiNTEwNDY5OWI3NTA2YWRmMmMzYmUwMzU2ZWI0NzUifQ.OW1ht2PJ61dowvKIrdXdc7UZluMW9o3eUX_8hVilc34",
// "token_type":"bearer",
// "expires_in":3600,
// "user":{"id":2,"name":"Admin","email":"admin@admin.com","email_verified_at":"2022-03-30T08:36:10.000000Z",
// "role_id":null,"token":"D5VDib7UYpEmTbvLa9RoTidgrDW2W7TFICapbf7VCUn9okEW7zW1g7S2lva9ca3T","created_at":"2021-12-22T23:48:18.000000Z","updated_at":"2022-03-31T11:03:40.000000Z"}},"status":200,"statusText":"OK","headers":{"cache-control":"no-cache, private, max-age=31536000","content-length":"525","content-type":"application/json","expires":"Fri, 31 Mar 2023 11:05:56 GMT"},"config":{"transitional":{"silentJSONParsing":true,"forcedJSONParsing":true,"clarifyTimeoutError":false},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1,"headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json"},
// "method":"post","url":"https://askanalyst-testing.akseerresearch.com/api/login","data":"{\"email\":\"admin@admin.com\",\"password\":\"admin12345\"}"},"request":{}}
